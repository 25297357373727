export const FIND_PRODUCT_BY_ID_REQUEST = 'FIND_PRODUCT_BY_ID_REQUEST'
export const FIND_PRODUCT_BY_ID_SUCCESS = 'FIND_PRODUCT_BY_ID_SUCCESS'
export const FIND_PRODUCT_BY_ID_FAILURE = 'FIND_PRODUCT_BY_ID_FAILURE'


export const FIND_PRODUCTS_REQUEST = 'FIND_PRODUCT_BY_CATEGORY_REQUEST'
export const FIND_PRODUCTS_SUCCESS = 'FIND_PRODUCT_BY_CATEGORY_SUCCESS'
export const FIND_PRODUCTS_FAILURE = 'FIND_PRODUCT_BY_CATEGORY_FAILURE'


export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST'
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS'
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE'


export const CREATE_PRODUCT_REQUEST = 'CREATE_PRODUCT_REQUEST'
export const CREATE_PRODUCT_SUCCESS = 'CREATE_PRODUCT_SUCCESS'
export const CREATE_PRODUCT_FAILURE = 'CREATE_PRODUCT_FAILURE'