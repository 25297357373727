import React from 'react'
import CreateProductForm from './CreateProductForm'

const CustomersTable = () => {
  return (
    <div>
      Cutomers Table
    </div>
  )
}

export default CustomersTable
